@use "../../index.scss";
form {
   display: flex;
   flex-direction: column;
   max-width: 500px;
   margin: auto;
   gap: 20px;

   input,
   textarea,
   button {
      font-family: index.$font-family;
      outline: none;
      height: 40px;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      font-size: 16px;
      padding: 0 5px;
      width: 100%;
   }
   .form-control {
      width: 100%;
      position: relative;
      small {
         position: absolute;
         color: index.$white;
         left: 5px;
         bottom: -1rem;
         font-size: 10px;
      }
   }

   textarea {
      height: 100px;
      resize: none;
      padding-top: 10px;
   }
   button {
      background-color: index.$blue;
      border: none;
      color: index.$white;
      text-transform: uppercase;
      transition: all 0.3s ease-out;
      &:hover {
         opacity: 0.9;
         cursor: pointer;
      }
   }
}
