@use "../../index.scss";

header {
   height: index.$header;
   position: fixed;
   width: 100%;
   background-color: index.$background;
   z-index: 10000;
   top: 0;
   nav {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-container {
         display: flex;
      }
      .menu {
         display: flex;
         gap: index.$mg-2;
         z-index: 10000;
         li {
            list-style: none;
            position: relative;
            &::after {
               content: "";
               position: absolute;
               width: 0;
               height: 2px;
               background-color: index.$blue;
               bottom: -2px;
               left: 0;
               transition: all 0.3s ease-out;
            }
            &:hover::after {
               width: 100%;
            }
            a {
               text-decoration: none;
               color: index.$white;
               font-size: index.$small;
               text-transform: uppercase;
            }
         }
      }
      .burger-container {
         display: none;
      }
   }
}

// ==== nav media queries ==== //
@media (max-width: index.$mobile-breaking-point) {
   header {
      nav {
         .menu {
            position: absolute;
            height: calc(100vh - index.$header);
            background-color: index.$background;
            top: index.$header;
            left: 0;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-out;
            height: 0;
            overflow: hidden;
         }
         .menu.isOpen {
            height: calc(100vh - 3.5rem);
         }

         .burger-container {
            display: block;
            position: relative;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
               cursor: pointer;
            }
            .burger-icon {
               height: 1px;
               width: 30px;
               background-color: none;
               &::before {
                  content: "";
                  position: absolute;
                  height: 1px;
                  width: 30px;
                  background-color: index.$white;
                  transform: translateY(-5px);
                  transition: all 0.3s ease-out;
               }
               &::after {
                  content: "";
                  position: absolute;
                  height: 1px;
                  width: 30px;
                  background-color: index.$white;
                  transform: translateY(5px);
                  transition: all 0.3s ease-out;
               }
            }
            .burger-icon.isOpen::before {
               transform: rotate(45deg);
            }
            .burger-icon.isOpen::after {
               transform: rotate(-45deg);
            }
         }
      }
   }
}
