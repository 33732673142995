@use "../../index.scss";
.items-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
   gap: 30px;
   margin-top: 3rem;
   .items-wrapper {
      margin-top: 4rem;
      background-color: rgb(49, 49, 49);
      border-radius: 20px;
      height: 260px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
         position: absolute;
         max-width: 250px;
         top: -5rem;
      }
      img:nth-child(2) {
         top: 5rem;
         opacity: 0.3;
      }
      .sub-title {
         margin-top: 6rem;
         text-transform: capitalize;
      }
   }
}
