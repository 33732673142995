@use "./index.scss";
.app {
   background-color: index.$background;
   // ===============Main ============== //
   main {
      margin-top: 3.5rem;
      .hero-container {
         flex-direction: column;
         gap: 30px;
         .hero-content {
            display: flex;
            flex-direction: column;
            gap: index.$mg-2;
            .hero-header {
               font-size: index.$large-2;
               text-transform: uppercase;
               line-height: 1;
            }

            .price {
               color: index.$blue;
               font-size: index.$large-1;
            }
            .btn-container {
               display: flex;
               gap: index.$mg-2;
               a {
                  text-transform: uppercase;
                  color: index.$white;
                  background-color: transparent;
                  outline: none;
                  padding: 7px index.$mg-3;
                  border: 1px solid index.$gray;
                  border-radius: 5px;
                  font-weight: index.$semi-bold;
                  font-family: index.$font-family;
                  font-size: 12px;
                  text-decoration: none;
               }
               :first-child {
                  background-color: index.$blue;
               }
            }
         }
         figure {
            width: 100%;
            position: relative;
            display: flex;

            img {
               width: 80%;
               height: auto;
               display: block;
            }
         }
      }
   }

   // ============ SECTIONS =========== //
   .content-header-wrapper {
      position: relative;
      margin-bottom: 3rem;
      .content-title {
         color: index.$blue;
         font-size: index.$large-2;
         text-transform: uppercase;
         text-align: center;
         opacity: 0.3;
         position: absolute;
         width: 100%;
         margin: auto;
         top: -(index.$mg-5);
         left: 0;
         z-index: 10;
      }
      .content-sub-title {
         text-align: center;
         z-index: 100;
      }
   }

   // ============= Footer =========== //
   footer {
      background-color: index.$footer-background;
      padding-bottom: 10px;
      .footer-container {
         .footer-top {
            justify-content: space-evenly;
            gap: 1rem;

            color: index.$white;
            small {
               text-transform: uppercase;
            }
            ul li {
               list-style: none;
               font-size: 12px;
               text-transform: capitalize;
               line-height: 2;
            }
            .socials {
               ul {
                  display: flex;
                  gap: 1rem;
               }
               li {
                  font-size: 20px;
               }
            }
         }
         .footer-bottom {
            margin-top: 1rem;
            text-align: center;
            small {
               font-size: 10px;
            }
         }
      }
   }
}

@media (min-width: index.$ipad-breaking-point) {
   .app {
      // ========== Main ===============//
      main {
         height: calc(100vh - 3.5rem);
         .hero-container {
            height: 100%;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            .hero-content {
               flex: 1;
               p,
               h2 {
                  max-width: 520px;
               }
            }
            figure {
               flex: 1;
               justify-content: flex-end;
            }
         }
      }
   }
}
