.service-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
   gap: 30px;
   margin-top: 3rem;
   .service-wrapper {
      background-color: rgb(49, 49, 49);
      padding: 2rem 2rem;
      border-radius: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
      img {
         max-width: 100px;
         height: auto;
         flex-shrink: 1;
      }
   }
}

@media (max-width: 848px) {
   .service-container {
      grid-template-columns: 1fr;
   }
}

@media (max-width: 457px) {
   .service-container {
      .service-wrapper {
         flex-direction: column;
         text-align: center;
         padding: 1rem 2rem;
         gap: 10px;
      }
   }
}
