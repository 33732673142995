.swiper {
   width: 100%;
   height: 100%;
   .swiper-button-prev,
   .swiper-button-next {
      color: white;
      &::after {
         font-size: 30px;
      }
   }
   .swiper-pagination {
      display: none;
   }
   .swiper-wrapper {
      .swiper-slide {
         font-size: 18px;
         background: rgb(49, 49, 49);
         height: 250px;
         width: 250px;
         border-radius: 20px;
         /* Center slide text vertically */
         display: -webkit-box;
         display: -ms-flexbox;
         display: -webkit-flex;
         display: flex;
         -webkit-box-pack: center;
         -ms-flex-pack: center;
         -webkit-justify-content: center;
         justify-content: center;
         -webkit-box-align: center;
         -ms-flex-align: center;
         -webkit-align-items: center;
         align-items: center;
      }
   }
}
